import React, {useState}            from 'react';
import bnc                          from 'bnc';
import {HBox}                       from '@sirius/ui-lib/src/blocks/Layout';
import {IconButtonColored}          from '@sirius/ui-lib/src/blocks/Controls/Button';
import {ServicesDropdown}           from '@sirius/ui-lib/src/blocks/Layout/UIHeader/ServicesDropdown';
import {useWindowIsMobile}          from '@sirius/ui-lib/src/subjects/WindowIsMobile';
import breakpoints                  from '@sirius/ui-lib/src/styles/values/breakpoints.json'
import {cluster}                    from 'CheopsRoot/clusters/current';
import {hasNotifications}           from 'Cheops/constants';
import ColorUtils                   from 'Cheops/Utils/ColorUtils';
import {setServices, useServices}   from 'Cheops/subjects/Services';
import {getHeaderServices}          from 'Cheops/actions/noopolis-ts';
import {passSuccess}                from 'Cheops/AjaxWrapper';
import * as auth_actions            from 'Cheops/actions/auth';
import NotificationsIcon            from '../../Notifications/NotificationsIcon';
import HeaderMenu                   from '../../HeaderMenu/HeaderMenu';
import                                   './index.less';

const block = new bnc('landings-header');

const maxMobileWidth = parseInt(breakpoints.max_mobile_width);

const getHasServices = (services: Spec.Auth.ServiceListResponseSuccessData) => !!services && (!!services?.groups.length || !!services?.other?.length);

type LandingsHeaderProps = {
    notifications_icon_background?: string;
    showNotifications:              () => void;
    show_notifications?:            boolean;
    white_widgets?:                 boolean;
    small_logo:                     boolean;
    land_header_with_bg:            boolean;
    back_path:                      string | null;
    course_color:                   string | null;
    addition_icon?:                 string | null;
    custom_icon?:                   string | null;
}

type RightWidgetsProps = {
    user_is_anon: boolean;
    menu_is_open: boolean;
    openMenu:     (e: React.MouseEvent) => void;
    closeMenu:    () => void;
} & Pick<LandingsHeaderProps, 'show_notifications' | 'showNotifications' | 'notifications_icon_background' | 'white_widgets'>;

export const RightWidgets = (
    {
        user_is_anon,
        show_notifications            = true,
        notifications_icon_background = 'transparent',
        white_widgets                 = false,
        showNotifications,
        openMenu,
        menu_is_open,
        closeMenu
    }: RightWidgetsProps
) => (
    <>
        {
            user_is_anon &&
            <div
                className = {block.el('enter-button')}
                onClick   = {() => auth_actions.redirectToAuth()}
            >
                Войти
            </div>
        }
        {
            hasNotifications && show_notifications && showNotifications &&
            <NotificationsIcon
                showNotifications={showNotifications}
                notifications_icon_background={notifications_icon_background}
            />
        }
        <div
            className = {
                block.el('menu-button') +
                block.el('menu-button').bod('black', !white_widgets)
            }
            onClick   = {openMenu}
        />
        <HeaderMenu
            menu_is_open                  = {menu_is_open}
            closeMenuClick                = {closeMenu}
            user_is_anon                  = {user_is_anon}
            showNotifications             = {showNotifications}
            notifications_icon_background = {NotificationsIcon.BACKGROUND.TRANSPARENT}
        />
    </>
);

const LandingsHeader : React.FC<LandingsHeaderProps> = (
    {
        notifications_icon_background = 'transparent',
        showNotifications,
        show_notifications = true,
        white_widgets = false,
        small_logo = false,
        land_header_with_bg = false,
        back_path = null,
        course_color = null,
        addition_icon= null,
    }) => {

    const services = useServices();
    const isMobile = useWindowIsMobile();

    const [user_is_anon] = useState<boolean>( !localStorage.getItem(CONFIG.Auth.localstorage_key) ); // TODO: useAuth()?
    const [menu_is_open, set_menu_is_open] = useState<boolean>( false );

    const [isServicesOpen, setIsServicesOpen] = useState<boolean>(false);

    const openMenu  = (e: React.MouseEvent) => {
        e.stopPropagation();
        set_menu_is_open(true);
    }

    const closeMenu = () => {
        set_menu_is_open(false);
    }

    const toggleServices = () => {
        setIsServicesOpen(false);
    }

    const hasServices = getHasServices(services);

    const handleOpenServices = () => {
        if (!hasServices) {
            getHeaderServices()
                .then(passSuccess)
                .then((services) => {
                    setServices(services);
                    setIsServicesOpen(true);
                })
            ;
        } else {
            setIsServicesOpen(true);
        }
    };

    return (
        <div
            className={
                block +
                block.bod('colored', land_header_with_bg) +
                block.mod('cluster', cluster.key)
            }
            style={{backgroundColor: course_color?.includes('rgba') ?  course_color : ColorUtils.darken(course_color)}}
        >
            {
                !back_path &&
                <HBox>
                    <div
                        className={
                            block.el('logo') +
                            block.el('logo').bod('white', white_widgets) +
                            block.el('logo').bod('small', small_logo)
                        }
                        onClick={() => {
                            location.hash = '#/'
                        }}
                    />
                    <IconButtonColored icon      = {`expand_${isServicesOpen ? 'less' : 'more'}`}
                                       palette   = {'colors-sirius-grey'}
                                       onAction  = {handleOpenServices}
                                       size      = {'s'}
                                       active    = {isServicesOpen}
                                       className = {
                                            block.el('logo-button') +
                                            block.el('logo-button').bod('small', small_logo)
                                       }
                    />
                    {
                        addition_icon &&
                        <div
                            className={
                                block.el('logo') +
                                block.el('logo').bod('white', white_widgets) +
                                block.el('logo').bod('small', small_logo) +
                                block.el('logo').mod('additional')
                            }
                            style={{backgroundImage: addition_icon}}
                            onClick={() => {
                                location.hash = '#/'
                            }}
                        />
                    }

                </HBox>

            }
            {
                back_path &&
                <a
                    href={back_path}
                    className={block.el('back-arrow')}
                />
            }
            <div className={block.el('right-widgets')}>
                <RightWidgets {...{
                        user_is_anon,
                        show_notifications,
                        showNotifications,
                        notifications_icon_background,
                        white_widgets,
                        openMenu,
                        menu_is_open,
                        closeMenu
                    }}
                />
            </div>
            {
                hasServices &&
                <ServicesDropdown {...services}
                                  onClose     = {toggleServices}
                                  isTablet    = {isMobile}
                                  tabletWidth = {maxMobileWidth}
                                  isOpened    = {isServicesOpen}
                />
            }
        </div>
    );
}

export default LandingsHeader;
