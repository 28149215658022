import React                    from "react";
import cloneDeep                from "lodash/cloneDeep";

import MarkedWithVideo          from "Smt/MarkedWithVideo";
import SmtDetailedAnswer        from "Smt/TaskPage/DetailedAnswer";
import {
    AnswersValidationReportType,
    getElementClientValidationError,
    ValidationReportType
}                               from "Smt/TaskPage/Task/ClientValidation";

import {
    ElementClientValidationLabel,
    block as ElementClientValidationBlock
}                               from "Cheops/components/ModulePassing/InputClientValidation/ElementClientValidationLabel";
import DetailedAnswer           from "Cheops/components/Section/DetailedAnswer";
import MatchAnswers             from "Cheops/components/ModulePassing/Answers/AnswerTypes/MatchAnswers/MatchAnswers";
import ProgrammingAnswer        from "Cheops/components/ModulePassing/Answers/AnswerTypes/ProgrammingAnswer/ProgrammingAnswer";
import SelectOnImageAnswer      from "Cheops/components/ModulePassing/Answers/AnswerTypes/SelectOnImageAnswer/SelectOnImageAnswer";
import SelectOptionAnswer       from "Cheops/components/ModulePassing/Answers/AnswerTypes/SelectOptionAnswer/SelectOptionAnswer";
import {OrderingAnswer}         from "Cheops/components/ModulePassing/Answers/AnswerTypes/OrderingAnswer";
import Assert                   from "Cheops/Utils/Assert";
import {TaskAnswerType}         from "Cheops/Model/Task";

import {Languages}              from "Lab/Task/Answers/AnswerTypes/Programming/AnswersProgrammingSettingsAspect";
import {TaskAnswersData}        from "Lab/Model/Task";

import MultipleAnswers          from "./AnswerTypes/MultipleAnswer/MultipleAnswers";
import AnswerType               from "./AnswerType";


interface Props {
    isSolutionToShow?: boolean;
    exportPageMode?: boolean;
    isHidden?: boolean;
    allowResend?: boolean;

    inputIndex?: number; // input index for inline layout
    inputMaxWidth?: number; // input max width for inline layout
    inline?: boolean;

    readOnly: boolean;
    elementContent?: any;
    answersData: TaskAnswersData | TaskAnswersData[];
    answerType: TaskAnswerType | TaskAnswerType[];
    userAnswer?: any;
    isSmt?: boolean;
    allMustBeFilled?: boolean;

    taskNum?: string | number;
    courseId?: any;// TODO
    moduleId?: any;// TODO
    elementLearn?: any; // TODO

    onAnswer?: (userAnswer: any, done: () => void) => void;

    onAnswerIsFilledChange?(value: boolean, hasErrors: boolean): void;

    formVisible?: boolean; // TODO
    isLocked?: boolean; // TODO
    solutionIsNotEditable?: boolean; // TODO
    currentElementLearnThread?: any; // TODO
    sendComment?(a: string, b: any): void; // TODO
    sendAnswer?(): void; // TODO
    openAnswerForm?(): void; // TODO
    closeAnswerForm?(): void; // TODO

    getFractionLatex?(value: string): Promise<Common.Math.LatexResponse>;

    customGetFileInfoAction?(contestId: number | string, hash: string): Promise<any>;

    taskToken?: string; // TODO it's for smt detailed
    contestId?: string | number;
    hideExpandSolutionPanel?: boolean;

    onSelectProgrammingLanguage?(language: Languages): void;

    // TODO is horrible pin for inline logic
    onInlineCompositeAnswerIsFilledChange?(answerIndex: number, value: boolean, hasErrors: boolean): void;

    AnswerComponents: Record<string, React.FC>;
    onlineState: any;
    isSavingInProgress: boolean;
    unlockNextTry: ()=>void;
    index?: number;
    validationReport?:        ValidationReportType;
    answersValidationReport?: AnswersValidationReportType;
}

const getAnswersValidationReport = ({field, elements}: ValidationReportType, answerIndex: number): AnswersValidationReportType => {
    const answersValidationReport = {
        field:    field    ? field   [answerIndex] : null,
        elements: elements ? elements[answerIndex] : null
    }
    return answersValidationReport;
}

export default class Answers extends React.Component<Props> {

    static defaultProps: Partial<Props> = {
        isSmt: false,
        exportPageMode: false,
        onAnswerIsFilledChange: () => {
        },
        onAnswer: () => {
        },
    };


    componentDidMount(): void {

        const {answerType, onAnswerIsFilledChange} = this.props;

        if (!Array.isArray(answerType) && onAnswerIsFilledChange) {

            onAnswerIsFilledChange(
                this.answerRef.current?.getIsAnswerFilled(),
                this.answerRef.current?.getAnswerHasError(),
            );

        }

    }

    answerRef = React.createRef<AnswerType>();

    filledAnswerIndexes: number[] = []; // TODO Clear on componentDidUpdate
    answerWithErrorsIndexes: number[] = []; // TODO Clear on componentDidUpdate


    onCompositeAnswerIsFilledChange = (answerIndex: number, value: boolean, hasErrors: boolean): void => {

        const {onAnswerIsFilledChange, allMustBeFilled, answerType} = this.props;

        const oldFilledLength = this.filledAnswerIndexes.length;
        const oldErrorsLength = this.answerWithErrorsIndexes.length;

        const filledIndex = this.filledAnswerIndexes.indexOf(answerIndex);
        const errorIndex = this.answerWithErrorsIndexes.indexOf(answerIndex);

        if (filledIndex >= 0 && !value) {

            this.filledAnswerIndexes.splice(filledIndex, 1);

        }

        if (filledIndex === -1 && value) {

            this.filledAnswerIndexes.push(answerIndex);

        }

        if (errorIndex >= 0 && !hasErrors) {

            this.answerWithErrorsIndexes.splice(errorIndex, 1);

        }

        if (errorIndex === -1 && hasErrors) {

            this.answerWithErrorsIndexes.push(answerIndex);

        }


        if (
            oldFilledLength !== this.filledAnswerIndexes.length
            || oldErrorsLength !== this.answerWithErrorsIndexes.length
        ) {

            let isFilled = this.filledAnswerIndexes.length > 0;

            if (allMustBeFilled) {

                isFilled = this.filledAnswerIndexes.length === answerType.length;

            }

            onAnswerIsFilledChange(
                isFilled,
                this.answerWithErrorsIndexes.length > 0,
            );

        }

    };

    setCompositeAnswer = (answerIndex: number, answer: any, done: () => void): void => { // TODO define type

        const {answerType, userAnswer, readOnly, onAnswer} = this.props;


        if (readOnly) {

            return;

        }

        let userAnswers = cloneDeep(userAnswer);

        if (!Array.isArray(userAnswers)) {

            userAnswers = Array(answerType.length).fill({solution: null});

        }

        if (answer === null) {

            answer = '';

        }

        userAnswers[answerIndex] = {solution: null};

        if (answer !== '') {

            userAnswers[answerIndex] = {solution: answer};

        }

        onAnswer(userAnswers, done);

    };

    getCompositeSingleProgress(typeIndex: number): any { // TODO define type

        const elementProgress = cloneDeep(this.props.elementLearn);

        if (!elementProgress) {

            return null;

        }

        if (elementProgress.solution?.answers?.length > 0) {

            elementProgress.solution.answers = elementProgress.solution.answers.map((answer: any) => {

                answer.answer = answer.answer[typeIndex];

                return answer;

            });

        }

        return elementProgress;

    }

    getAnswerComponent(): typeof AnswerType {

        const {isSmt, answersData, answerType, AnswerComponents = {} } = this.props;

        Assert(!Array.isArray(answerType), 'answerType should not be an array');
        Assert(!Array.isArray(answersData), 'answersData should not be an array');

        if (answerType in AnswerComponents){
            return AnswerComponents[answerType] as unknown as typeof AnswerType;
        }

        switch (answerType) {
            case TaskAnswerType.NONE:
                return null;

            case TaskAnswerType.NUMBER:
            case TaskAnswerType.STRING:

                return MultipleAnswers;

            case TaskAnswerType.MULTI:
            case TaskAnswerType.SINGLE:
            case TaskAnswerType.SELECT:
                return answersData.selectType === 'image'
                    ? SelectOnImageAnswer
                    : SelectOptionAnswer
                ;

            case TaskAnswerType.MATCH:

                return MatchAnswers;

            case TaskAnswerType.DETAILED:

                // @ts-ignore
                return isSmt ? SmtDetailedAnswer : DetailedAnswer;

            case TaskAnswerType.PROGRAMMING:

                // @ts-ignore
                return ProgrammingAnswer;

            case TaskAnswerType.ORDERING:
                // @ts-ignore
                return OrderingAnswer;
        }

        throw new Error('Unknown answer type');

    }

    onAnswer = (userAnswer: any): void => {

        const {onAnswer, readOnly, onAnswerIsFilledChange} = this.props;
        if (readOnly) {
            return;
        }
        onAnswer(
            userAnswer,
            () => onAnswerIsFilledChange(
                this.answerRef.current?.getIsAnswerFilled(),
                this.answerRef.current?.getAnswerHasError(),
            ),
        );
    };


    getElementContentForProgramming = (): any => {
        const {elementContent = {}, answersData} = this.props;
        return {...elementContent, answersData};
    };

    canBeRenderedInMarkdown(type: TaskAnswerType): boolean {
        return true;
    }

    render(): React.ReactNode {

        let answers = [];

        const {
            isSmt,
            readOnly,
            answersData,
            answerType,
            userAnswer,
            getFractionLatex,
            taskNum,
            inputIndex,
            inputMaxWidth,
            inline,
            onInlineCompositeAnswerIsFilledChange,
            isSavingInProgress,
            isHidden,
            allowResend,
            validationReport
        } = this.props;

        if (Array.isArray(answerType)) {

            // TODO is horrible pin for inline logic
            const onAnswerIsFilledChange = onInlineCompositeAnswerIsFilledChange || this.onCompositeAnswerIsFilledChange;

            Assert(Array.isArray(answersData), 'answersData must be an array');

            for (let [answerIndex, type] of answerType.entries()) {
                if (Number.isFinite(inputIndex) && inputIndex !== answerIndex) {
                    continue;
                }

                if (!this.canBeRenderedInMarkdown(type)) {
                    answers.push("Тип ответа не поддерживается для вставки в условие");
                    continue;
                }

                let singleUserAnswer;
                if (Array.isArray(userAnswer) && 'solution' in userAnswer[answerIndex]) {
                    singleUserAnswer = userAnswer[answerIndex]?.solution;
                }

                const answersValidationReport       = validationReport ? getAnswersValidationReport     (validationReport, answerIndex) : null;
                const elementClientValidationReport = validationReport ? getElementClientValidationError(validationReport, answerIndex) : null;

                answers.push(
                    <div
                        className={
                            "task__composite_input " +
                            (
                                elementClientValidationReport
                                    ? ElementClientValidationBlock.mod(
                                        `has-level`,
                                        elementClientValidationReport.level
                                    )
                                    : ''
                            )
                        }
                        key={`answers_${this.props.taskNum}_${answerIndex}_${type}`}
                    >
                        {!Number.isFinite(inputIndex)
                            && answersData[answerIndex].comment
                            &&  <MarkedWithVideo className="task__text">
                                    {answersData[answerIndex].comment}
                                </MarkedWithVideo>
                        }

                        <Answers
                            {...this.props}
                            onAnswerIsFilledChange={(v, e) => onAnswerIsFilledChange(answerIndex, v, e)}
                            answerType={type}
                            taskNum={this.props.taskNum}
                            userAnswer={singleUserAnswer}
                            answersData={answersData[answerIndex]}
                            onAnswer={(answer, done) => this.setCompositeAnswer(answerIndex, answer, done)}
                            getFractionLatex={this.props.getFractionLatex}
                            /* Props below are for detailed type */
                            elementLearn={this.getCompositeSingleProgress(answerIndex)}
                            answersValidationReport={answersValidationReport}
                        />
                        {   elementClientValidationReport &&
                            <ElementClientValidationLabel report={elementClientValidationReport}/>
                        }
                    </div>
                );

            }

            return answers;

        }

        Assert(!Array.isArray(answersData), 'answersData should not be an array');

        const AnswerComponent = this.getAnswerComponent();

        if (!AnswerComponent) {
            return null;
        }

        let _userAnswer = cloneDeep(this.props.userAnswer);

        if (answerType === TaskAnswerType.DETAILED) { // TODO Remove this
            if (!_userAnswer || _userAnswer.length === 0) {
                _userAnswer = {
                    message: "",
                    attachments: [],
                };
            }
        }

        const notEditable = readOnly !== undefined
                                ? readOnly
                                : !!this.props.isLocked
        ;

        return <AnswerComponent
            ref={this.answerRef}
            isSmt={isSmt}
            key={`answer_${taskNum}`}
            unlockNextTry={this.props.unlockNextTry}
            readOnly={notEditable}
            answersData={answersData}
            answerType={answerType}
            userAnswer={_userAnswer}
            onAnswer={this.onAnswer}
            getFractionLatexAction={getFractionLatex}
            inputMaxWidth={inputMaxWidth}
            inline={inline || answersData?.layout?.inline}
            isSavingInProgress={isSavingInProgress}
            allowResend={allowResend}
            isHidden={isHidden}
            isSolutionToShow={this.props.isSolutionToShow}
            exportPageMode={this.props.exportPageMode}
            courseId={this.props.courseId}
            answers={_userAnswer}
            elementLearn={!this.props.elementLearn?.solution ? {...this.props.elementLearn, solution: {answers:[]}} : this.props.elementLearn}
            elementContent={this.getElementContentForProgramming()}
            sendAnswer={this.props.sendAnswer}
            formVisible={this.props.formVisible}
            openAnswerForm={this.props.openAnswerForm}
            closeAnswerForm={this.props.closeAnswerForm}
            solutionIsNotEditable={this.props.solutionIsNotEditable}
            hideExpandSolutionPanel={this.props.hideExpandSolutionPanel}
            onSelectProgrammingLanguage={this.props.onSelectProgrammingLanguage}
            // @ts-ignore old detailed answer
            is_locked={this.props.isLocked}
            course_id={this.props.courseId}
            contestId={this.props.contestId}
            module_id={this.props.moduleId}
            allow_file_upload={answersData.areFilesAllowed}
            current_element_learn_thread={this.props.currentElementLearnThread}
            element_learn={this.props.elementLearn}
            element_content={this.props.elementContent}
            sendComment={this.props.sendComment}
            answer_type={this.props.answerType}
            taskToken={this.props.taskToken}
            customGetFileInfoAction={this.props.customGetFileInfoAction}
            onlineState={this.props.onlineState}
            taskNum={taskNum}
            validationReport={this.props.validationReport}
            answersValidationReport={this.props.answersValidationReport}
        />;

    }

}
