import React                    from "react";
import bnc                      from "bnc";
import {GrowGap, HBox, LGap, VBox} from "@sirius/ui-lib/src/blocks/Layout";
import {Icon}                   from "@sirius/ui-lib/src/blocks/Controls/Icon";
import {Button}                 from "@sirius/ui-lib/src/blocks/Controls/Button";
import {DEPLOY_NAME}            from "Cheops/constants";

import professor                from "./shedule-link-professor.png";
import                               "./index.less";

const block                 = new bnc('shedule-link-card');
const shedulelink           = "/schedule.pdf";
const shedulePrintlink      = "/schedule_print.pdf";
const sheduleFileLabel      = "Расписание курсов на 2025 год.pdf";
const sheduleFileLabelShort = "Расписание '25";
const CPM                   = CONFIG.DeployName === DEPLOY_NAME.CPM;

const SheduleLink: React.FC = () =>
    <a
        target    = "_blank"
        rel       = "noopener noreferrer"
        className = {block.el('link')}
        href      = {shedulelink}
    >
        <span
            className={
                block.el('link-label') +
                block.el('link-label').mod('full')
            }
        >{sheduleFileLabel}</span>
        <span
            className={
                block.el('link-label') +
                block.el('link-label').mod('short')
            }
        >{sheduleFileLabelShort}</span>
    </a>
;

export const SheduleLinkCompact: React.FC = () =>
    <HBox
        center
        className={ block.el('compact') + block.bod('cpm', CPM) }>
        <Icon icon="pending_actions" size="s" className={block.el('icon')}/>
        <LGap />
        <SheduleLink />
    </HBox>
;

export const SheduleLinkCard: React.FC = () =>
    <HBox className={block +  block.bod('cpm', CPM)}>
        <img className={block.el('professor')} src={professor}/>
        <LGap/>
        <VBox>
            <SheduleLink />
            <LGap/>
            <form method="get" action={shedulePrintlink} target="_blank">
                <Button
                    size="xs"
                    icon="print"
                    iconPlace="end"
                    type="submit"
                >Файл для печати</Button>
            </form>
        </VBox>
        <GrowGap />
        <LGap />
        <Icon icon="pending_actions" size="xl" className={block.el('icon')}/>
    </HBox>
;
