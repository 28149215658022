import React        from "react";
import bnc          from "bnc";
import {HBox, VBox} from "@sirius/ui-lib/src/blocks/Layout/index";
import {Button}     from "@sirius/ui-lib/src/blocks/Controls/Button";
import                   "./InformationLinkCard.less";

const block = new bnc('info-link-card');

const cards = {
    schedule: {
        link: "/schedule.pdf",
        title: "Расписание курсов на 2025 год.pdf",
        buttonText: "Файл для печати",
        description: "",
        iconName: "shedule-link-professor.png"
    },
    ai: {
        link: "/ai-navigator?bannerId=X4vD7B",
        title: "Искусственный интеллект",
        buttonText: "Подробнее",
        description: "Курсы по\u00A0математике, программированию и\u00A0ИИ",
        iconName: "ai-icon.svg"
    },
    vos: {
        link: "/vos/?bannerId=AzjJEA",
        title: "Знакомство со школьным этапом всероссийской олимпиады",
        buttonText: "Подробнее",
        description: "",
        iconName: "vos-icon.svg"
    },
    bday: {
        link: "/5years",
        title: "День рождения Сириус.Курсов",
        buttonText: "5 лет — за 5 дней!",
        description: "",
        iconName: "bday-icon.svg"
    },
    aiDay: {
        link: "/ai-day?xid=Aq9OKA",
        title: "Дни ИИ и информатики",
        buttonText: "Участвовать!",
        description: "4-8 декабря на Сириус.Курсах",
        iconName: "aiDay-icon.svg"
    },
};

type Props = {
    variant: keyof typeof cards;
}

export const InformationLinkCard = ({variant}: Props) => {

    const {link, title, buttonText, iconName, description} = cards[variant];

    return (
        <a href   = {link}
           target = "_blank"
           style  = {{textDecoration: 'none'}}
        >
            <HBox className={block}>
                <img className = {block.el('icon')}
                     src       = {require(`Cheops/containers/InformationLinkCard/icons/${iconName}`)}
                     alt       = "Иконка"
                />

                <VBox style={{width: '90%'}}>
                    <h3 className={block.el('title')}>
                        {title}
                    </h3>
                    {
                        description
                        && <p className={block.el('date')}>
                                {description}
                           </p>
                    }
                    <Button size="xs" className={block.el('button')}>
                        {buttonText}
                    </Button>
                </VBox>
            </HBox>
        </a>
    )
};
